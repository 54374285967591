import React from 'react';
import logo from './28blabs_logo.png';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faBars, faCheckSquare, faChevronCircleUp, faChevronDown, faEnvelope, faHeart, faLink, faSearch, faUserCircle } from '@fortawesome/free-solid-svg-icons';

library.add(fab, faBars, faCheckSquare, faChevronCircleUp, faChevronDown, faEnvelope, faHeart, faLink, faSearch, faUserCircle)


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />

          <p>An innovation lab creating products that make lasting societal impact.</p>

        <div className="col-md-4" >
          <ul className="nav justify-content-center mt-3 social-icons">
            <li className="nav-item">
              <a className="nav-link" href="mailto:hello@28blabs.com" target="_blank" rel="noopener noreferrer" >
              <FontAwesomeIcon className="App-link" icon="envelope" />
              </a>
            </li>
            <li className="nav-item">
                <a className="nav-link" href="https://www.linkedin.com/company/28blabs" target="_blank" rel="noopener noreferrer" >
                <FontAwesomeIcon className="App-link" icon={['fab', 'linkedin']} />
                </a>
            </li> 
            <li className="nav-item">
                <a className="nav-link" href="https://www.twitter.com/28blabs" target="_blank" rel="noopener noreferrer" >
                  <FontAwesomeIcon className="App-link" icon={['fab', 'twitter']} />
                </a>
            </li>

          </ul>
        </div>
      </header>
    </div>
  );
}

export default App;
